(function() {
  var retailerQS = ['retailer_store_experience', 'addtobag'];
  var suppressEcomCookie = Drupal.settings.suppress_ecom.cookie_name;
  var ttlMs = Drupal.settings.suppress_ecom.cookie_lifetime * 60 * 1000;
  var suppressEcomQS = Drupal.settings.suppress_ecom.query_string;
  function getCookie(cookieName) {
    var suppressCookie = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieList = decodedCookie.split(';');
    var cookieListSize = cookieList.length;
    for (var i = 0; i < cookieListSize; i++) {
      var cookieItem = cookieList[i].trim();
      if (cookieItem.indexOf(suppressCookie) === 0) {
        return cookieItem.substring(suppressCookie.length, cookieItem.length);
      }
    }
    return '';
  }
  function setCookie(cookieName, cookieValue, ttl) {
    var date = new Date();
    date.setTime(date.getTime() + ttl);
    var e = 'expires=' + date.toUTCString();
    document.cookie = cookieName + '=' + cookieValue + ';' + e + ';path=/';
  }
  function getQueryParam(suppressParam) {
    var qs = window.location.search || '';
    var URLHash = window.location.hash || '';
    var URLParams = {};
    var paramList = (qs[0] === '?' ? qs.substr(1) : qs).split('&');
    var paramListSize = paramList.length;
    var suppressParamIndex = -1;
    var param;
    for (var i = 0; i < paramListSize; i++) {
      param = paramList[i].split('=');
      URLParams[decodeURIComponent(param[0])] = decodeURIComponent(param[1] || '').trim();
      if (param[0] === suppressParam) {
        suppressParamIndex = i;
      }
    }
    if (URLParams[suppressParam] === '0') {
      // removes the Suppress E-Comm param from URL
      if ('history' in window && suppressParamIndex !== -1) {
        paramList.splice(suppressParamIndex, 1);
        var URLParamString = paramList.length > 0 ? '?' + paramList.join('&') : '';
        window.history.replaceState({}, document.title, window.location.pathname + URLParamString + URLHash);
      }
      return URLParams[suppressParam];
    }
    return null;
  }
  function addBodyClass() {
    document.addEventListener('DOMContentLoaded', function() {
      this.body.className += ' ' + 'is-retailer';
    });
  }
  var suppressEcomParam = retailerQS.indexOf(suppressEcomQS) !== -1 ? getQueryParam(suppressEcomQS) : null;
  // If the user has the short-lived cookie, then add the body class
  if (getCookie(suppressEcomCookie) === '1') {
    addBodyClass();
  // Otherwise we'll set the short-lived cookie and add the body class
  } else {
    if (!!suppressEcomParam) {
      setCookie(suppressEcomCookie, 1, ttlMs);
      addBodyClass();
    }
  }
})();
